import { GridContainer } from 'components/activity-feed/grid/container'
import React from 'react'
import { FC, ReactNode } from 'react'

export type WorkFeedLayout = 'grid' | 'list'
export const WorkFeedWrapper: FC<{
  layout: WorkFeedLayout
  children: ReactNode
}> = ({ layout, children }) => {
  if (layout === 'list')
    return (
      <div
        className="max-w-[740px] lg:max-w-[calc(100vw-580px)] xl:max-w-[740px] px-4 lg:px-6 xl:px-14 py-8 w-full m-auto"
        id="work-feed"
      >
        {children}
      </div>
    )

  return (
    <GridContainer className="p-10" id="work-feed">
      {children}
    </GridContainer>
  )
}
