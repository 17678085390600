import { FC } from 'react'
import { updateCanBeAddedToStory, useActivityGrid } from './context'
import React from 'react'
import { Button, Loader, useModalContext } from 'src/design-system'
import { successToast } from 'app/packs/src/utils/success-toast'
import { SelectionAll, SelectionSlash } from '@phosphor-icons/react'
import { Activity } from 'store/modules/activities'

export const StoryCta: FC<{
  activities: Activity[]
  onSubmit(): Promise<void>
}> = ({ activities, onSubmit }) => {
  const { storyMode, addedUpdates, addingToStoryId, toggleUpdateInStory } =
    useActivityGrid()
  const [loading, setLoading] = React.useState(false)

  const { closeModal } = useModalContext()

  if (storyMode === 'none') return null

  const ctaCopy = storyMode === 'creating' ? 'Create Story' : 'Update Story'

  return (
    <>
      <div className="fixed bottom-0 inset-x-0 h-60 filter blur-md shadow-lg from-transparent to-white bg-gradient-to-b animate-fade-in pointer-events-none"></div>
      <div className="p-5 rounded-lg border border-gray-100 flex items-center gap-5 fixed bottom-8 inset-x-0 mx-auto w-max bg-white shadow-lg animate-fade-slide-in">
        <p className="mb-0">{addedUpdates.length} updates selected</p>
        <Button
          variant="default"
          disabled={!addedUpdates.length || loading}
          className="flex items-center gap-2"
          onClick={async () => {
            setLoading(true)
            await onSubmit()
            setLoading(false)
            if (addingToStoryId) {
              successToast('Story updated!')
              closeModal()
            }
          }}
        >
          {loading ? 'Loading...' : ctaCopy}
          {loading && <Loader variant="paper" className="w-4 h-4" />}
        </Button>

        <Button
          variant="outline"
          className="items-center flex"
          onClick={() => {
            activities.forEach((activity) => {
              const record = activity.record as Activity['record']
              const enabled = updateCanBeAddedToStory(record)
              const added =
                enabled && addedUpdates && addedUpdates.includes(record)
              if (enabled && !added) toggleUpdateInStory(record)
            })
          }}
        >
          <SelectionAll />
          Select all
        </Button>
        <Button
          variant="outline"
          className="items-center flex"
          onClick={() => {
            activities.forEach((activity) => {
              const record = activity.record as Activity['record']
              const enabled = updateCanBeAddedToStory(record)
              const added =
                enabled && addedUpdates && addedUpdates.includes(record)
              if (enabled && added) toggleUpdateInStory(record)
            })
          }}
        >
          <SelectionSlash />
          Remove all
        </Button>
      </div>
    </>
  )
}
