import { Activity } from 'store/modules/activities'
import React from 'react'
import { Button } from 'src/design-system'
import { ActivityGridProvider, AllowedStoryUpdateType } from './context'
import { StoryCta } from './story-cta'
import { WordFeedStoryMode } from '../../home-page/work-feed/work-feed.types'
import { store } from 'store/index'
import { ActivityItem } from './types/activity-item'
import { AddItemButton } from '../grid/add-item-button'
import { Tab } from 'components/create-form'

export const ActivityGrid = ({
  activities,
  onLoadMore,
  hasNextPage,
  storyMode = 'creating',
  addingToStoryId,
  onSubmitStory,
  addItemTitle,
  addItemActiveTab,
}: {
  activities: Activity[]
  onLoadMore?: () => void
  storyMode?: WordFeedStoryMode
  addingToStoryId?: string
  onSubmitStory: (
    updates: AllowedStoryUpdateType[],
    addingToStoryId?: string
  ) => Promise<void>
  addItemTitle?: string
  addItemActiveTab?: Tab
  hasNextPage?: boolean
}) => {
  const [addedUpdates, setUpdates] = React.useState<AllowedStoryUpdateType[]>(
    []
  )

  React.useEffect(() => {
    setUpdates(
      store.stories
        .byId(addingToStoryId)
        ?.storyItems.filter((item) => item.aasmState == 'approved')
        .map((i) => i.storyable) || []
    )
  }, [addingToStoryId])

  return (
    <ActivityGridProvider
      value={{
        storyMode,
        addingToStoryId,
        toggleUpdateInStory: (u) =>
          setUpdates((updates) => {
            if (updates.includes(u))
              return updates.filter((update) => update !== u)
            return [...updates, u]
          }),
        addedUpdates,
      }}
    >
      <AddItemButton title={addItemTitle} activeTab={addItemActiveTab} />

      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
      {hasNextPage && (
        <Button
          variant="outline"
          colourVariant="theme"
          size="default"
          onClick={onLoadMore}
          className="m-auto col-[1/-1] my-4"
        >
          Load more
        </Button>
      )}
      <StoryCta
        activities={activities}
        onSubmit={() => onSubmitStory?.(addedUpdates, addingToStoryId)}
      />
    </ActivityGridProvider>
  )
}
