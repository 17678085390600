import { ListBullets, SquaresFour } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { WorkFeedLayout } from '../wrapper'

type LayourButtonProps = {
  onLayoutChange: (layout: WorkFeedLayout) => void
  layout: WorkFeedLayout
}

export const LayoutButton = (props: LayourButtonProps) => {
  const { onLayoutChange, layout } = props

  return (
    <button
      className="flex gap-2 border-gray-100 border rounded-[4px] items-center px-2 h-[34px]"
      onClick={() => onLayoutChange(layout === 'list' ? 'grid' : 'list')}
    >
      <ListBullets
        className={cn(
          'w-5 h-5 text-gray-600',
          layout === 'list' && '!text-green-500'
        )}
      />
      <span aria-hidden className="w-px h-5 bg-gray-100" />
      <SquaresFour
        className={cn(
          'w-5 h-5 text-gray-600',
          layout === 'grid' && '!text-green-500'
        )}
      />
    </button>
  )
}
